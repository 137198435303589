<template>
    <footer class="site-footer">
        <div class="site-footer__copy">
            <span>
                &copy; 2019 - {{ currentYear }} Ileana Sofronie
            </span>
           <span>All Rights Reserved  </span>
        </div>
    </footer>
</template>

<script>
    export default {
        computed: {
            currentYear() {
                return new Date().getFullYear();
            }
        },
    }
</script>

<style lang="scss" scoped>
    .site-footer {
        @extend %site-grid;
        display: none;
        &__copy {
            grid-column: span 4;
            text-align: center;
            span {
                display: block;
                font-size:rem(14px);
                font-weight: 400;
                line-height: rem(20px);
                margin:rem(5px 0);
            }
        }
    }
</style>