import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
      { 
        path: '/', 
        name: 'home',
        component: () => import(/* webpackChunkName: "home-view" */ '@/views/HomeView.vue')
      },
      { 
        path: '/:title',
        name: 'page-view',
        props: true, 
        component: () => import(/* webpackChunkName: "page-view" */ '@/views/PageView.vue')
      },
      { 
        path: '/about', 
        name: 'about',
        component: () => import(/* webpackChunkName: "about-view" */ '@/views/AboutView.vue')
      },
      { 
        path: '/playground',
        name:'playground',
        component: () => import(/* webpackChunkName: "playground-view" */ '@/views/PlaygroundView.vue')
      },
      { 
        path: '/shop',
        name:'shop',
        component: () => import(/* webpackChunkName: "products-view" */ '@/views/ProductList.vue')
      },
      { 
        path: '/contact', 
        name: 'contact',
        component: () => import(/* webpackChunkName: "contact-view" */ '@/views/ContactView.vue')
      }
    ],
    
});

export default router;

router.beforeEach((to, from, next) => {
  document.body.classList.add('transition')
  setTimeout(() => {
    document.body.classList.remove('transition')
  }, 1500)
  next()
})