<template>
    <header class="site-header">
        <div class="site-header__logo">
            <router-link to="/">
                <svg>
                    <path d="M14.2872 6.60899L14.3512 6.57699C17.2312 5.29699 19.0232 5.39299 19.7272 6.86499C19.9512 7.37699 19.9512 7.37699 19.9512 8.33699C19.9512 9.13699 19.9192 9.42499 19.7592 10.097C19.1832 12.433 18.2552 14.833 15.0872 22.001C12.8792 26.929 12.5592 27.665 11.3752 30.225C9.96717 33.265 9.29517 34.769 9.29517 35.025C9.32717 35.537 8.78317 35.985 8.46317 35.729C8.39917 35.697 8.27117 35.601 8.23917 35.505C8.04717 35.153 8.46317 33.841 9.87117 30.417C11.0872 27.409 11.5032 26.417 15.1512 18.833C17.3592 14.161 18.2872 12.049 18.7672 10.321C19.3752 8.27299 19.2792 7.18499 18.4792 6.80099C18.1912 6.67299 18.0952 6.64099 17.4552 6.67299C16.8472 6.67299 16.6232 6.73699 16.0152 6.92899C11.5032 8.49699 5.26317 14.065 3.02317 18.545C2.54317 19.473 2.28717 20.273 2.25517 20.849C2.25517 21.265 2.28717 21.297 2.44717 21.425C2.60717 21.553 2.67117 21.585 3.27917 21.521C3.66317 21.521 4.04717 21.489 4.14317 21.457C4.36717 21.393 4.62317 21.649 4.62317 21.905C4.62317 22.225 3.91917 22.897 3.40717 23.057C2.63917 23.345 1.87117 23.249 1.39117 22.737C1.00717 22.321 0.847168 21.905 0.847168 21.105C0.847168 20.209 0.975168 19.697 1.48717 18.705C2.38317 16.785 3.88717 14.801 6.19117 12.497C8.81517 9.87299 11.7272 7.79299 14.2872 6.60899Z" fill="#333333"/>
                    <path d="M30.2022 22.737L30.1702 22.769C30.3622 22.737 30.5222 22.865 30.5222 23.057C30.4902 23.153 30.4262 23.249 30.3302 23.249C30.2662 23.281 30.1702 23.345 30.0422 23.377C29.3062 23.665 24.0902 25.297 22.6822 25.681C19.9942 26.449 18.7142 26.449 18.1702 25.681C18.0102 25.425 17.9782 25.393 18.0102 24.913C18.0102 24.273 18.2022 23.665 18.5542 22.929C18.6502 22.737 19.1622 21.841 19.5142 21.329C20.1542 20.337 21.5942 18.577 23.0342 16.945C23.7382 16.177 24.6662 15.089 25.0822 14.545C25.4982 14.033 25.9462 13.521 26.0102 13.489C26.2342 13.329 26.5222 13.361 26.6822 13.521C26.8422 13.713 26.8422 13.841 26.7142 14.033C26.7142 14.097 26.1062 14.801 25.4342 15.569C22.4262 18.993 20.5062 21.457 19.4182 23.249C18.7782 24.273 18.6182 24.817 18.7142 25.297C18.8422 25.873 20.0582 25.841 22.6182 25.201C24.3142 24.785 25.6262 24.401 27.8662 23.505C28.7622 23.153 29.6582 22.865 30.2022 22.737Z" fill="#333333"/>
                    <path d="M45.3227 22.769V22.737C45.6107 22.737 45.7707 23.025 45.6747 23.249C45.6107 23.441 45.4507 23.537 45.2907 23.505C45.2267 23.505 45.1627 23.473 45.1307 23.473C45.0347 23.377 44.9707 23.377 43.8827 23.761C42.2507 24.337 41.8987 24.465 39.6587 25.105C35.6587 26.321 33.5467 26.769 31.7867 26.833C30.6027 26.929 29.8347 26.737 29.2267 26.321C28.9067 26.065 28.7147 25.809 28.6827 25.425C28.6827 25.425 28.6827 25.425 28.6827 25.393C28.5867 24.785 28.7147 24.177 29.0987 23.729C29.0987 23.697 29.0987 23.665 29.0987 23.665C29.9947 22.353 31.5307 21.649 32.2667 22.225C32.7467 22.609 32.6827 23.249 32.0427 23.761C31.4347 24.209 30.8587 24.369 30.3147 24.145C30.0587 24.017 29.9627 24.017 29.9307 24.049C29.7707 24.177 29.4187 24.849 29.3867 25.041C29.2907 25.553 29.6107 25.969 30.3467 26.193C30.7947 26.321 32.3307 26.321 33.2587 26.193C35.2427 25.873 37.8667 25.169 40.5867 24.209C42.8267 23.409 44.8427 22.769 45.1307 22.769C45.1947 22.769 45.2267 22.769 45.3227 22.769ZM31.4027 22.961C31.1467 22.993 30.7627 23.249 30.7947 23.281C30.8907 23.377 31.1467 23.313 31.2747 23.217C31.3707 23.153 31.4667 23.057 31.5307 22.993C31.5947 22.897 31.5947 22.897 31.4027 22.961Z" fill="#333333"/>
                    <path d="M62.5039 22.865V22.929C62.7599 22.865 63.0159 23.089 62.9839 23.377C62.9839 23.505 62.8559 23.633 62.7279 23.665C62.0879 23.825 60.3919 24.273 58.3759 24.689C55.8159 25.297 53.6719 25.457 52.3279 25.169C51.4639 25.009 50.8879 24.689 50.2159 24.145C49.7679 23.761 49.5439 23.601 49.4479 23.601C49.0959 23.601 47.7199 24.497 46.0239 25.777C44.1359 27.217 43.8159 27.441 43.3679 27.473C42.6319 27.537 42.2479 26.897 42.5679 26.065C42.8559 25.233 43.7199 23.985 44.5519 23.153C45.5759 22.161 46.4719 21.713 47.4959 21.713C48.1359 21.713 48.6479 21.873 49.1279 22.225C49.3199 22.385 49.5439 22.513 49.6079 22.513C49.8639 22.513 50.2799 22.769 50.5039 23.089C51.0159 23.761 51.0799 23.825 51.3359 23.985C51.9759 24.401 52.8399 24.561 54.1839 24.561C56.3279 24.561 57.7039 24.273 61.0319 23.249C61.4479 23.121 62.0559 22.961 62.5039 22.865ZM47.2719 23.505L47.7519 23.217H47.4959C46.8879 23.217 46.0239 23.761 45.1919 24.753C44.9679 25.009 44.8079 25.201 44.8079 25.233C44.8079 25.265 44.9359 25.169 45.1279 25.041C45.2879 24.913 45.7359 24.561 46.0879 24.273C46.4719 24.017 46.9839 23.665 47.2719 23.505Z" fill="#333333"/>
                    <path d="M74.8252 22.961H74.7612C74.9852 22.865 75.2092 22.737 75.4332 22.673V23.057C75.0492 23.217 74.4412 23.473 73.7372 23.761C70.8572 24.913 69.6092 25.297 68.6812 25.297C68.2972 25.297 68.2332 25.297 68.0412 25.169C67.4972 24.785 67.5292 24.177 68.1372 22.577C68.2652 22.193 68.3932 21.841 68.3932 21.841C68.3932 21.713 68.1372 21.777 67.5292 22.033C66.6972 22.385 65.4812 23.057 63.6892 24.177C61.9612 25.233 61.8012 25.297 61.5452 25.169C61.4492 25.137 61.3852 25.041 61.3532 24.945C61.2892 24.785 61.4172 24.177 61.6092 23.729L61.7372 23.377C61.7372 23.377 61.9612 22.609 62.5052 22.673C62.6332 22.705 62.8572 22.897 62.8572 23.057C62.8572 23.121 62.6972 23.473 62.5372 23.793C62.3132 24.177 62.1852 24.497 62.2172 24.497C62.2172 24.497 62.6012 24.273 63.0492 24.017C65.6092 22.353 67.6252 21.329 68.3612 21.233C68.6812 21.201 68.9052 21.297 69.0652 21.521C69.1932 21.777 69.1612 22.097 68.7452 23.153C68.3292 24.273 68.2652 24.625 68.4572 24.785C68.5532 24.913 68.6172 24.913 69.0332 24.881C69.9292 24.785 71.2732 24.369 74.8252 22.961Z" fill="#333333"/>
                    <path d="M92.9414 22.865V22.929C93.1974 22.865 93.4534 23.089 93.4214 23.377C93.4214 23.505 93.2934 23.633 93.1654 23.665C92.5254 23.825 90.8294 24.273 88.8134 24.689C86.2534 25.297 84.1094 25.457 82.7654 25.169C81.9014 25.009 81.3254 24.689 80.6534 24.145C80.2054 23.761 79.9814 23.601 79.8854 23.601C79.5334 23.601 78.1574 24.497 76.4614 25.777C74.5734 27.217 74.2534 27.441 73.8054 27.473C73.0694 27.537 72.6854 26.897 73.0054 26.065C73.2934 25.233 74.1574 23.985 74.9894 23.153C76.0134 22.161 76.9094 21.713 77.9334 21.713C78.5734 21.713 79.0854 21.873 79.5654 22.225C79.7574 22.385 79.9814 22.513 80.0454 22.513C80.3014 22.513 80.7174 22.769 80.9414 23.089C81.4534 23.761 81.5174 23.825 81.7734 23.985C82.4134 24.401 83.2774 24.561 84.6214 24.561C86.7654 24.561 88.1414 24.273 91.4694 23.249C91.8854 23.121 92.4934 22.961 92.9414 22.865ZM77.7094 23.505L78.1894 23.217H77.9334C77.3254 23.217 76.4614 23.761 75.6294 24.753C75.4054 25.009 75.2454 25.201 75.2454 25.233C75.2454 25.265 75.3734 25.169 75.5654 25.041C75.7254 24.913 76.1734 24.561 76.5254 24.273C76.9094 24.017 77.4214 23.665 77.7094 23.505Z" fill="#333333"/>
                    <path d="M105.788 12.145L105.692 12.081C110.364 7.05699 118.908 1.80899 124.476 0.528992C125.244 0.336992 125.532 0.304993 126.492 0.304993C127.484 0.304993 127.676 0.336992 128.06 0.464993C129.404 0.944992 130.076 2.16099 129.788 3.56899C129.436 5.45699 127.196 8.84899 125.916 9.58499C125.148 10.001 124.476 9.71299 124.476 8.97699C124.476 8.68899 124.892 8.08099 126.076 6.54499C127.516 4.65699 128.22 2.86499 127.676 2.51299C126.844 1.90499 124.252 2.38499 121.276 3.69699C117.5 5.29699 112.668 8.46499 108.86 11.729C106.748 13.585 105.468 14.929 104.764 16.145C103.74 17.841 104.124 18.513 106.3 18.897C106.94 19.025 107.484 19.057 113.436 19.025C119.9 18.993 121.98 19.057 124.124 19.249C128.924 19.729 131.644 20.753 132.572 22.417C132.828 22.833 132.86 22.961 132.892 23.569C132.924 24.529 132.668 25.297 131.804 26.385C129.116 29.809 120.38 34.417 112.86 36.369C111.036 36.849 109.18 37.073 108.348 36.913C107.74 36.817 107.26 36.593 107.004 36.241C106.812 35.985 106.78 35.889 106.78 35.473C106.78 34.641 107.292 33.873 108.764 32.529C110.172 31.249 112.092 29.905 113.404 29.297C114.204 28.945 114.812 28.721 115.452 28.689C116.028 28.657 116.252 28.721 116.252 29.009C116.252 29.233 116.124 29.329 115.516 29.393C114.812 29.521 114.236 29.713 113.372 30.225C111.42 31.313 108.668 33.713 108.06 34.833C107.58 35.697 107.836 35.857 109.372 35.793C111.772 35.697 115.932 34.449 120.412 32.497C126.748 29.713 130.876 26.737 131.708 24.337C132.188 22.865 130.908 21.777 128.06 21.105C125.5 20.529 122.076 20.369 116.028 20.529C108.252 20.753 106.012 20.625 104.444 19.889C101.788 18.705 102.236 15.921 105.788 12.145Z" fill="#333333"/>
                    <path d="M147.135 22.737L147.103 22.769C147.263 22.769 147.391 22.961 147.327 23.089C147.263 23.153 147.231 23.249 147.135 23.249C146.687 23.313 144.959 23.569 141.791 24.081C140.543 24.273 139.327 24.497 139.071 24.529C138.623 24.593 138.591 24.593 138.047 25.041C137.471 25.521 136.607 25.937 136.191 25.937C135.871 25.937 135.615 25.809 135.487 25.585C135.359 25.361 135.359 24.785 135.487 24.497C135.487 24.401 135.583 23.793 135.935 23.761C136.127 23.761 136.287 23.921 136.287 24.113C136.287 24.177 136.255 24.401 136.191 24.593C136.063 25.041 136.063 25.297 136.191 25.329C136.351 25.393 136.799 25.201 137.247 24.913L137.631 24.657H137.343C136.703 24.657 136.383 24.529 136.383 24.209C136.383 23.729 137.535 23.185 138.303 23.345C138.655 23.377 138.911 23.633 138.911 23.889V24.017L139.327 23.985C140.159 23.889 142.335 23.569 143.871 23.249C145.791 22.865 146.431 22.737 147.135 22.737ZM138.143 24.081H138.175C138.303 23.985 138.303 23.921 138.111 23.921C137.887 23.921 137.215 24.081 137.119 24.145C137.023 24.241 138.047 24.177 138.143 24.081Z" fill="#333333"/>
                    <path d="M159.072 22.801L159.104 22.865C159.36 22.737 159.584 22.993 159.488 23.249C159.488 23.281 159.392 23.377 159.36 23.377C159.232 23.409 159.136 23.473 159.04 23.505C158.656 23.665 158.048 23.921 157.632 24.081C155.648 24.785 149.76 26.193 146.08 26.833C145.408 26.961 144.864 27.089 144.832 27.089C144.832 27.089 144 28.657 142.976 30.545C140.704 34.705 139.84 36.241 139.616 36.497C139.392 36.753 139.104 36.753 138.976 36.497C138.88 36.369 138.88 36.273 139.264 35.281C139.968 33.265 140.32 31.953 140.544 30.481C140.704 29.585 140.736 28.209 140.608 27.601C140.448 26.897 140.448 26.897 141.632 26.833C142.432 26.833 144.16 26.641 144.224 26.609C144.224 26.609 144.64 25.873 145.152 25.009L146.016 23.377C146.016 23.377 146.976 21.841 146.976 21.681C146.976 21.649 147.424 20.913 147.968 19.985C149.888 16.593 151.392 14.449 152.416 13.521C152.992 12.977 153.344 12.881 153.632 13.137C153.952 13.393 153.856 13.937 153.312 14.929C152.32 16.689 149.248 20.625 147.776 22.033C147.456 22.353 147.264 22.673 146.304 24.401C145.696 25.457 145.216 26.385 145.216 26.417C145.216 26.449 145.248 26.449 145.312 26.449C145.76 26.449 150.368 25.553 152.576 25.009C155.008 24.433 155.68 24.209 158.176 23.153C158.496 23.025 158.816 22.897 159.072 22.801ZM149.76 18.481V18.449C148.864 19.889 148.832 19.953 149.632 18.929C150.56 17.745 151.744 15.985 152.224 15.057C152.832 13.937 150.976 16.465 149.76 18.481ZM143.808 27.409L143.968 27.217L143.424 27.281C143.136 27.345 142.56 27.377 142.112 27.409C141.472 27.473 141.344 27.473 141.376 27.537C141.408 27.569 141.44 27.857 141.472 28.177C141.6 29.009 141.44 30.385 140.992 32.177C140.832 32.721 140.832 32.785 140.864 32.753C140.896 32.721 141.568 31.569 142.336 30.161C143.104 28.753 143.776 27.505 143.808 27.409Z" fill="#333333"/>
                    <path d="M172.828 22.769V22.737C173.052 22.641 173.276 22.897 173.18 23.153C173.148 23.249 173.052 23.281 172.956 23.313C171.804 23.569 167.804 24.401 167.228 24.465C165.34 24.721 164.38 24.561 163.42 23.761C163.004 23.473 162.908 23.409 162.684 23.409C162.332 23.409 161.724 23.697 160.988 24.177C160.188 24.689 158.684 25.969 157.916 26.801C157.532 27.185 157.18 27.505 157.148 27.537C157.052 27.569 156.956 27.537 156.86 27.473C156.572 27.345 156.604 27.185 157.02 26.449C157.212 26.033 157.436 25.553 157.532 25.329C157.66 24.913 158.428 22.865 158.492 22.769C158.716 22.545 159.004 22.449 159.196 22.577C159.324 22.641 159.42 22.737 159.452 22.833C159.484 22.993 159.196 23.889 158.812 24.785L158.588 25.297L159.452 24.657C161.372 23.185 162.524 22.641 163.196 22.961C163.292 22.993 163.548 23.185 163.804 23.409C164.572 24.017 164.892 24.113 166.012 24.145C167.708 24.177 169.692 23.793 171.996 23.057C172.22 22.961 172.508 22.865 172.828 22.769Z" fill="#333333"/>
                    <path d="M183.823 22.737L183.791 22.769C183.951 22.769 184.079 22.961 184.015 23.089C183.951 23.153 183.919 23.249 183.823 23.249C183.375 23.313 181.647 23.569 178.479 24.081C177.231 24.273 176.015 24.497 175.759 24.529C175.311 24.593 175.279 24.593 174.735 25.041C174.159 25.521 173.295 25.937 172.879 25.937C172.559 25.937 172.303 25.809 172.175 25.585C172.047 25.361 172.047 24.785 172.175 24.497C172.175 24.401 172.271 23.793 172.623 23.761C172.815 23.761 172.975 23.921 172.975 24.113C172.975 24.177 172.943 24.401 172.879 24.593C172.751 25.041 172.751 25.297 172.879 25.329C173.039 25.393 173.487 25.201 173.935 24.913L174.319 24.657H174.031C173.391 24.657 173.071 24.529 173.071 24.209C173.071 23.729 174.223 23.185 174.991 23.345C175.343 23.377 175.599 23.633 175.599 23.889V24.017L176.015 23.985C176.847 23.889 179.023 23.569 180.559 23.249C182.479 22.865 183.119 22.737 183.823 22.737ZM174.831 24.081H174.863C174.991 23.985 174.991 23.921 174.799 23.921C174.575 23.921 173.903 24.081 173.807 24.145C173.711 24.241 174.735 24.177 174.831 24.081Z" fill="#333333"/>
                    <path d="M195.888 22.961H195.824C196.048 22.865 196.272 22.737 196.496 22.673V23.057C196.112 23.217 195.504 23.473 194.8 23.761C191.92 24.913 190.672 25.297 189.744 25.297C189.36 25.297 189.296 25.297 189.104 25.169C188.56 24.785 188.592 24.177 189.2 22.577C189.328 22.193 189.456 21.841 189.456 21.841C189.456 21.713 189.2 21.777 188.592 22.033C187.76 22.385 186.544 23.057 184.752 24.177C183.024 25.233 182.864 25.297 182.608 25.169C182.512 25.137 182.448 25.041 182.416 24.945C182.352 24.785 182.48 24.177 182.672 23.729L182.8 23.377C182.8 23.377 183.024 22.609 183.568 22.673C183.696 22.705 183.92 22.897 183.92 23.057C183.92 23.121 183.76 23.473 183.6 23.793C183.376 24.177 183.248 24.497 183.28 24.497C183.28 24.497 183.664 24.273 184.112 24.017C186.672 22.353 188.688 21.329 189.424 21.233C189.744 21.201 189.968 21.297 190.128 21.521C190.256 21.777 190.224 22.097 189.808 23.153C189.392 24.273 189.328 24.625 189.52 24.785C189.616 24.913 189.68 24.913 190.096 24.881C190.992 24.785 192.336 24.369 195.888 22.961Z" fill="#333333"/>
                    <path d="M198.772 21.393L198.74 21.329C198.74 21.905 198.292 22.225 197.812 22.001C197.332 21.809 197.332 21.137 197.844 20.881C197.908 20.817 198.1 20.817 198.228 20.817C198.612 20.817 198.772 21.009 198.772 21.393ZM209.812 22.737H209.844C210.036 22.769 210.1 23.025 209.908 23.153C209.684 23.313 208.98 23.537 206.484 24.209C202.164 25.393 200.948 25.681 199.572 25.905C198.036 26.161 197.332 26.161 196.628 25.841C196.052 25.617 195.796 25.297 195.668 24.657C195.636 24.209 195.86 23.601 195.924 23.505C196.052 23.249 196.404 23.089 196.628 23.089C196.724 23.089 196.916 23.153 197.012 23.249C197.204 23.441 197.236 23.505 197.236 23.697C197.236 23.889 197.204 23.985 196.98 24.145C196.436 24.561 196.468 25.105 197.076 25.297C198.26 25.777 201.492 25.169 206.836 23.537C208.724 22.961 209.62 22.737 209.812 22.737Z" fill="#333333"/>
                    <path d="M224.448 22.769V22.737C224.736 22.737 224.896 23.025 224.8 23.249C224.736 23.441 224.576 23.537 224.416 23.505C224.352 23.505 224.288 23.473 224.256 23.473C224.16 23.377 224.096 23.377 223.008 23.761C221.376 24.337 221.024 24.465 218.784 25.105C214.784 26.321 212.672 26.769 210.912 26.833C209.728 26.929 208.96 26.737 208.352 26.321C208.032 26.065 207.84 25.809 207.808 25.425C207.808 25.425 207.808 25.425 207.808 25.393C207.712 24.785 207.84 24.177 208.224 23.729C208.224 23.697 208.224 23.665 208.224 23.665C209.12 22.353 210.656 21.649 211.392 22.225C211.872 22.609 211.808 23.249 211.168 23.761C210.56 24.209 209.984 24.369 209.44 24.145C209.184 24.017 209.088 24.017 209.056 24.049C208.896 24.177 208.544 24.849 208.512 25.041C208.416 25.553 208.736 25.969 209.472 26.193C209.92 26.321 211.456 26.321 212.384 26.193C214.368 25.873 216.992 25.169 219.712 24.209C221.952 23.409 223.968 22.769 224.256 22.769C224.32 22.769 224.352 22.769 224.448 22.769ZM210.528 22.961C210.272 22.993 209.888 23.249 209.92 23.281C210.016 23.377 210.272 23.313 210.4 23.217C210.496 23.153 210.592 23.057 210.656 22.993C210.72 22.897 210.72 22.897 210.528 22.961Z" fill="#333333"/>
                </svg>
            </router-link> 
        </div>
        <div className="site-header__location">
            <span>Currently based in<strong>50.9375° N, 6.9603° E</strong></span>
        </div>
        <nav class="site-header__navigation" role="navigation">
            <ul>
                <li>
                    <router-link to="/">Home</router-link> 
                </li>
                <li>
                    <router-link to="/about">About</router-link>
                </li>
                <li>
                    <router-link to="/playground">Playground</router-link>
                </li>
                <li>
                    <router-link to="/contact">Inquire</router-link>
                </li>
            </ul>
        </nav>
    </header>
    <mobile-navigation />
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';

    export default {
    components: { MobileNavigation }
}
</script>

<style lang="scss" scoped>
    .site-header {
        @extend %site-grid;
        padding: rem(25px 0);
       &__logo {
            grid-column: span 4;
            svg {
                display: block;
                width: rem(220px);
                height:rem(50px);
                margin: 0;
            }
        }
        &__location {
            display: none;
            padding: rem(8px 0 0);
            font-size:rem(14px);
            font-weight: 400;
            line-height: rem(20px);
            
            strong {
                display:block;
                font-size:rem(14px);
                font-weight: 700;
                line-height: rem(20px);
            }
        }
        &__navigation {
            display: none;
        }
        @include desktop {
            &__logo {
                grid-column: span 2;
            }
            &__location {
                display: block;
                grid-column: 1;
                grid-row: 2;
            }
            &__navigation {
                grid-column: span 2;
                display: block;
                ul {
                    display: block;
                    list-style: none;
                    margin:0;
                    text-align: right;
                    
                    li {
                        display: inline-block;
                        a {
                            cursor: pointer;
                            color:var(--color-black);
                            font-family: var(--special-font);
                            font-size: rem(18px);
                            display: block;
                            padding:rem(0px 25px);
                            text-decoration: none;
                            &::after {
                                content: '';
                                background-color: var(--link-color);
                                display: block;
                                height: rem(1.5px);
                                width: 0%;
                                transition: all ease-in-out .4s;
                            }
                            &:hover {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>