<template>
    <div v-if="!loaded" class="site-loader" :class="{ 'slide-up': slideUpTriggered }">
        <div class="site-loader__words">{{ currentWord }}</div>
    </div>
    <div v-else >
    <SiteHeader />
        <main role="main">
            <router-view />
        </main>
    <SiteFooter/>
    </div>
    <SiteCursor/>
</template>


<script>
import SiteHeader from './SiteHeader.vue';
import SiteFooter from './SiteFooter.vue';
import SiteCursor from './SiteCursor.vue';

export default {
    components: {
        SiteHeader,
        SiteFooter,
        SiteCursor,
    },
    data() {
        return {
            words: ['INTUITIVE', 'ENGAGING', 'DYNAMIC', 'RESPONSIVE', 'USER-FOCUSED'],
            wordIndex: 0,
            slideUpTriggered: false,
            loaded: false
        }
    },
    computed: {
        currentWord() {
            return this.words[this.wordIndex];
}
    },
    mounted() {
        this.showWords();
    },
    methods: {
        showWords() {
            if (this.wordIndex < this.words.length) {
                this.wordIndex++;

                if (this.wordIndex === this.words.length) {
                    setTimeout(this.triggerSlideUp, 500);
                } else {
                    setTimeout(this.showWords, 350); 
                }
            }
        },
        triggerSlideUp() {
            this.slideUpTriggered = true;
            setTimeout(() => {
                this.loaded = true;
            }, 350);
        }
    }
}
</script>


<style lang="scss">
    .site-loader{
        background-color: var(--color-black);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &::after{
            
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            bottom: 0;
            position: absolute;
        }

        &__words {
            font-size: 10vw;
            font-weight: 700;
            line-height: 13vw;
            text-transform: uppercase;
            color: var(--color-white);
        }

        &.slide-up::after {
            background-color: var(--color-white);
            animation: slideUp .5s forwards;
        }
    }

</style>
