<template>
  <SiteContent>
    <div>Content</div>
  </SiteContent>
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import SiteContent from './components/layout/SiteContent.vue';

export default {
  name: 'App',
  components: {
      SiteContent
  },
  setup() {
    const siteData = reactive({
      title: `Ileana Sofronie | Product designer in Köln`,
      description: `Accomplished product designer with a focus on innovation, aesthetics, and user-centricity. Crafting fully functional digital products that leave an indelible mark.`,
      keyowrds: `product designer, innovation, aesthetics, user-centric design, digital products, collaboration, human-centered approach, exceptional products, design experiences.`,
      meta: [
        { name: 'description', content: computed(() => siteData.description) },
        { name: 'keywords', content: computed(() => siteData.keywords) },
        { property: 'og:title', content: computed(() => siteData.title) },
        { property: 'og:description', content: computed(() => siteData.description) },
        { property: 'og:image', content: '/images/og-image.png' },
      ]
    })
    useHead({
      title: computed(() => siteData.title),
      meta: siteData.meta,
      // meta: [
      //   {
      //     name: `description`,
      //     content: computed(() => siteData.description),
      //   },
      //   {
      //     name: `keyowrds`,
      //     content: computed(() => siteData.keyowrds)
      //   }
      //   ],
        htmlAttrs: {
        lang: "en",
      },
     
    })
  },
}
</script>

<style lang="scss">
  .transition {
    width: 100%;
    height: 100vh;
    position: absolute;
    top:0;
    left:0;
    right: 0;
    // pointer-events: none;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        // background-color: rgba(red,.2);
        // animation-delay:1s;
        animation: slideUp 1.5s forwards;
        z-index: 2;
      }
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        background-color: var(--color-black);
         animation-delay:1.5s;
        animation: slideDown 1.5s forwards;
        z-index: 10;
      }
   }

   @keyframes slideUp {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0%);
        }
    }

    @keyframes slideDown {
        0% {
            transform: translateY(0%);
        }
        100% {
            transform: translateY(-100%);
        }
    }

</style>