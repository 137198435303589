<template>
    <section class="mobile-navigation">
        <div 
            @click="toggleMenu" 
            class="mobile-navigation__burger" 
            :class="{ active: isActive }">
            <span></span>
        </div>
        <nav class="mobile-navigation__items" :class="{ open: isOpen }">
            <ul>
                <li v-for="(link, index) in links" :key="index" @click="toggleMenu">
                    <router-link :to="link.route">{{ link.text }}</router-link>
                </li>
            </ul>
        </nav>
    </section>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isOpen: false,
      links: [
        { route: '/', text: 'Home' },
        { route: '/about', text: 'About' },
        { route: '/playground', text: 'Playground' },
        { route: '/contact', text: 'Inquire' },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
      this.isOpen = !this.isOpen;
      document.body.classList.toggle('no-scroll', this.isOpen);
    }
  }
}
</script>
<style lang="scss" scoped>
    .mobile-navigation {
        display: block;
        &__burger {
            cursor: pointer;
            height: rem(25px);
            width: rem(35px);
            position: absolute;
            top:rem(35px);
            right: rem(20px);
            z-index: 11;
            span {
                background-color: var(--color-black);
                display: block;
                position: relative;
                height: rem(1px);
                width: 100%;
                &::after, &::before {
                    background-color: var(--color-black);
                    display: block;
                    content: '';
                    height: rem(1px);
                    width: 100%;
                    position: absolute;
                    transition: transform ease-in-out .3s;
                    top:7px;
                }
                &::before {
                    transform: rotate(-45deg);
                }
                &::after {
                    transform: rotate(45deg);
                } 
            }
            &.active span {
                background-color: transparent;
                &::before, &::after {
                    background-color: var(--color-white);
                    transform: rotate(45deg);
                }
            }
        }
        &__items{ 
            background-color: var(--color-black);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            transform: translateY(-100%);
            transition: transform ease-in-out .3s;
            z-index: 10;
            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                padding:rem(150px 32px 0  32px);
                li a {
                    font-family: var(--special-font);
                    font-size: rem(50px);
                    line-height: rem(60px);
                    color: var(--color-white);
                    text-decoration: none;
                }
            }
            &.open{
                transform: translateY(0%);
            }
        }
        @include desktop {
            display: none;
        }
    }
</style>