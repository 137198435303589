<template>
    <div class="site-cursor" :style="{ left: x + 'px', top: y + 'px' }">
    </div>
  </template>
    
  <script>
  export default {
    data() {
      return {
        x: 0,
        y: 0,
  
      };
    },
    methods: {
      updateCursorPosition(e) {
        this.x = e.clientX;
        this.y = e.clientY;
      },
    },
    mounted() {
      window.addEventListener('mousemove', this.updateCursorPosition);
    },
    beforeUnmount() {
      window.removeEventListener('mousemove', this.updateCursorPosition);
    },
  };
  </script>
    
  <style lang="scss" scoped>
  .site-cursor {
    pointer-events: none; 
    @include desktop {
      position: fixed;
      top: 0;
      left: 0;
      width: rem(20px);
      height: rem(20px);
      mix-blend-mode: difference;
      transition: left .4s ease-out, top .4s ease-out;
  
      &::before {
        display: block;
        content: '';
        border: solid rem(2px #333);
        border-radius: 50%;
        padding: rem(20px);
      }
    }
  }
  
  </style>  